<template>
	<S2SForm title="Pushy">
		<S2SCard title="Send Pushy">
			<v-form ref="pushy">
				<v-select :items="fromOptions" item-value="id" item-text="label" label="From" v-model="from" @change="getSubs()"></v-select>

				<v-layout column v-if="showForm">
					<v-row>
						<v-col cols="5">
							<v-select
								:items="subs"
								item-value="name"
								item-text="name"
								return-object
								label="To"
								v-model="pushyPayload.to"
								:disabled="checkToken()"
								clearable
							></v-select>
						</v-col>
						<v-col cols="2" class="text-center" align-self="center">- OR -</v-col>
						<v-col cols="5">
							<v-text-field
								:disabled="checkDropdown()"
								v-model="pushyPayload.tokens"
								label="Device Token(s)"
								persistent-hint
								hint="Seperate multiple tokens with a comma."
								clearable
							></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-textarea
								label="Message"
								auto-grow
								rows="5"
								v-model="pushyPayload.message"
								:rules="[v => !!v || 'Message is required']"
							></v-textarea>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-btn @click="dialog = true" :disabled="!pushyPayload.to && !pushyPayload.tokens">
								SEND
								<v-icon>send</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-layout>

				<v-dialog v-model="dialog" persistent max-width="600px">
					<v-card>
						<v-card-title>
							<span class="headline">Are you sure you want to send?</span>
						</v-card-title>
						<v-card-text>
							<v-simple-table>
								<template>
									<thead>
										<tr>
											<th>From</th>
											<th>To</th>
											<th>Message</th>
										</tr>
									</thead>
									<tbody>
										<td>{{ getFrom(from) }}</td>
										<td>
											<span v-if="pushyPayload.to">{{ pushyPayload.to.name }}</span>
											<span v-else>{{ pushyPayload.tokens }}</span>
										</td>
										<td>
											<v-textarea readonly :value="pushyPayload.message" :auto-grow="false"></v-textarea>
										</td>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
						<v-card-actions>
							<div class="flex-grow-1"></div>
							<v-btn color="blue darken-1" text @click="dialog = !dialog">Close</v-btn>
							<v-btn color="accent darken-1" text @click="send()">Ok</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-form>
		</S2SCard>
	</S2SForm>
</template>

<script>
export default {
	data: function() {
		return {
			from: null,
			showForm: false,
			dialog: false,
			pushyPayload: {
				to: null,
				tokens: null,
				message: "Thanks for using Shop2Shop!!\n\nRegards,\nThe Shop2Shop Team"
			},
			fromOptions: [
				{ id: process.env.VUE_APP_PUSHY_KEY_ORDER_APP, label: "Order App" },
				{ id: process.env.VUE_APP_PUSHY_KEY_TAP2PAY, label: "Tap2Pay" }
			]
		};
	},

	computed: {
		subs: function() {
			return this.$store.state.pushy.subs;
		}
	},

	methods: {
		send: async function() {
			const form = this.$refs.pushy;
			const valid = await form.validate();
			if (!valid) return;

			// Sub or token
			if (this.pushyPayload.to) {
				this.$store.dispatch("pushy/send", {
					key: this.from,
					payload: { to: `/topics/${this.pushyPayload.to.name}`, data: { message: this.pushyPayload.message } }
				});
			} else {
				this.$store.dispatch("pushy/send", {
					key: this.from,
					payload: { tokens: [this.pushyPayload.tokens], data: { message: this.pushyPayload.message } }
				});
			}

			// reset fields
			form.reset();
			this.showForm = false;
			this.dialog = false;
		},

		getSubs: function() {
			this.$store.dispatch("pushy/fetchSubs", this.from);
			this.showForm = true;
		},

		checkDropdown() {
			if (this.pushyPayload.to && this.pushyPayload.to.name) {
				return true;
			} else {
				return false;
			}
		},

		checkToken() {
			if (this.pushyPayload.tokens) {
				return true;
			} else {
				return false;
			}
		},

		getFrom(from) {
			let val = this.fromOptions.filter(option => {
				return option.id === from;
			});

			if (val.length) {
				return val[0].label;
			}
		}
	}
};
</script>
